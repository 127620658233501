<template>
    <Dialog v-model:visible="showDialogVencendo" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
            :style="{width: '40vw'}" header="Certificados próximos do vencimento">
        <DataTable v-if="vencendo.length" :value="vencendo" responsiveLayout="scroll">
            <Column field="local_configuracao" header="Local configuração"></Column>
            <Column field="nome" header="Nome"></Column>
            <Column header="Vencimento">
                <template #body="{data}">
                    {{ formatDate(data.vencimento) }}
                </template>
            </Column>
        </DataTable>
        <h4 v-else>Nenhum certificado próximo do vencimento</h4>
    </Dialog>
    <Dialog v-model:visible="showDialogVencidos" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
            :style="{width: '40vw'}" header="Certificados vencidos">
        <DataTable v-if="vencidos.length" :value="vencidos" responsiveLayout="scroll">
            <Column field="local_configuracao" header="Local configuração"></Column>
            <Column field="nome" header="Nome"></Column>
            <Column header="Vencimento">
                <template #body="{data}">
                    {{ formatDate(data.vencimento) }}
                </template>
            </Column>
        </DataTable>
        <h4 v-else>Nenhum certificado vencido</h4>
    </Dialog>
    <EsocialHeader :load="load" :showTipo="false" title="Visão geral"/>
    <div class="grid">
        <div class="col-4">
            <WidgetSituacoes :eventoTipo="EsocialEventoTipoEnum.S2210"
                             :item="this.records.find(value => value.evento_tipo === EsocialEventoTipoEnum.S2210) || {}"
                             eventoTipoTitle="Comunicação de Acidente de Trabalho"/>
        </div>
        <div class="col-4">
            <WidgetSituacoes :eventoTipo="EsocialEventoTipoEnum.S2220"
                             :item="this.records.find(value => value.evento_tipo === EsocialEventoTipoEnum.S2220) || {}"
                             eventoTipoTitle="Monitoramento da Saúde do Trabalhador"/>
        </div>
        <div class="col-4">
            <WidgetSituacoes :eventoTipo="EsocialEventoTipoEnum.S2240"
                             :item="this.records.find(value => value.evento_tipo === EsocialEventoTipoEnum.S2240) || {}"
                             eventoTipoTitle="Condições Ambientais do Trabalho - Agentes Nocivos"/>
        </div>
        <div class="col-12 mb-6">
            <Card class="shadow-4">
                <template #title>
                    Informações de gestão adicionais
                </template>
                <template #content>
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <a href="javascript:void(0)" @click="showDialogVencendo = true">
                                    Certificados próximos do vencimento
                                </a>
                            </td>
                            <td class="pl-2"><strong>{{ vencendo.length }}</strong></td>
                        </tr>
                        <tr>
                            <td>
                                <a href="javascript:void(0)" @click="showDialogVencidos = true">
                                    Certificados vencidos
                                </a>
                            </td>
                            <td class="pl-2"><strong>{{ vencidos.length }}</strong></td>
                        </tr>
                        </tbody>
                    </table>
                </template>
            </Card>
        </div>
    </div>
</template>

<script>
import BaseService from '../../../services/BaseService';
import {
    EsocialEventoSituacaoEnum,
    EsocialEventoSituacaoEnumColor,
    EsocialEventoSituacaoEnumLabel
} from '../eventos/enums/EsocialEventoSituacaoEnum';
import { EsocialEventoTipoEnum } from '../eventos/enums/EsocialEventoTipoEnum';
import WidgetSituacoes from './components/WidgetSituacoes.vue';
import EsocialHeader from '../_shared/EsocialHeader.vue';
import { lightFormat } from 'date-fns';

export default {
    components: { EsocialHeader, WidgetSituacoes },
    data() {
        return {
            records: [],
            checkS2210: false,
            EsocialEventoTipoEnum,
            EsocialEventoSituacaoEnum,
            EsocialEventoSituacaoEnumLabel,
            EsocialEventoSituacaoEnumColor,
            vencendo: [],
            showDialogVencendo: false,
            vencidos: [],
            showDialogVencidos: false
        };
    },
    mounted() {
        this.$service = new BaseService('/esocial/dashboard');
        this.load();
    },
    methods: {
        formatDate(dateString) {
            const formatDates = lightFormat(new Date(dateString), 'dd/MM/yyyy');
            return `${formatDates}`;
        },
        async load(filter = null) {
            this.loading = true;
            const { data } = await this.$service.findAll({
                filter: filter
            });
            this.records = data;
            this.vencidos = this.records.filter(value => value.vencido === true);
            this.vencendo = this.records.filter(value => value.vencendo === true);
            this.loading = false;
        }
    }
};
</script>

<style lang="scss">
</style>
