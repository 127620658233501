<template>
    <Card class="shadow-4">
        <template #title>
            <Checkbox v-model="selectAll" :binary="true" inputId="txtSelectAll"/>
            <label class="ml-2" for="txtSelectAll">
                {{ eventoTipo }}
            </label>
            <p style="float: right">Total:
                {{ item['total'] || 0 }}
            </p>
        </template>
        <template #subtitle>
            {{ eventoTipoTitle }}
        </template>
        <template #content>
            <table class="w-full">
                <thead>
                <tr>
                    <th class="text-left">Status</th>
                    <th class="text-center">Quantidade</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <a href="javascript:void(0)"
                           @click="this.routePush(eventoTipo, EsocialEventoSituacaoEnum.AGUARDANDO_ENVIO)">
                            {{ EsocialEventoSituacaoEnumLabel.get(EsocialEventoSituacaoEnum.AGUARDANDO_ENVIO) }}
                        </a>
                    </td>
                    <td class="text-center">
                        {{ item[EsocialEventoSituacaoEnumKey.get(EsocialEventoSituacaoEnum.AGUARDANDO_ENVIO)] || 0 }}
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="javascript:void(0)"
                           @click="this.routePush(eventoTipo, EsocialEventoSituacaoEnum.ESOCIAL_AGUARDANDO_RETORNO)">
                            {{
                                EsocialEventoSituacaoEnumLabel.get(EsocialEventoSituacaoEnum.ESOCIAL_AGUARDANDO_RETORNO)
                            }}
                        </a>
                    </td>
                    <td class="text-center">
                        {{
                            item[EsocialEventoSituacaoEnumKey.get(EsocialEventoSituacaoEnum.ESOCIAL_AGUARDANDO_RETORNO)] || 0
                        }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <hr>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="javascript:void(0)"
                           @click="this.routePush(eventoTipo, EsocialEventoSituacaoEnum.ERRO_XML)">
                            {{ EsocialEventoSituacaoEnumLabel.get(EsocialEventoSituacaoEnum.ERRO_XML) }}
                        </a>
                    </td>
                    <td class="text-center">
                        {{ item[EsocialEventoSituacaoEnumKey.get(EsocialEventoSituacaoEnum.ERRO_XML)] || 0 }}
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="javascript:void(0)"
                           @click="this.routePush(eventoTipo, EsocialEventoSituacaoEnum.ESOCIAL_ERRO_XML)">
                            {{
                                EsocialEventoSituacaoEnumLabel.get(EsocialEventoSituacaoEnum.ESOCIAL_ERRO_XML)
                            }}
                        </a>
                    </td>
                    <td class="text-center">
                        {{ item[EsocialEventoSituacaoEnumKey.get(EsocialEventoSituacaoEnum.ESOCIAL_ERRO_XML)] || 0 }}
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="javascript:void(0)"
                           @click="this.routePush(eventoTipo, EsocialEventoSituacaoEnum.ESOCIAL_ERRO_LOTE)">
                            {{
                                EsocialEventoSituacaoEnumLabel.get(EsocialEventoSituacaoEnum.ESOCIAL_ERRO_LOTE)
                            }}
                        </a>
                    </td>
                    <td class="text-center">
                        {{ item[EsocialEventoSituacaoEnumKey.get(EsocialEventoSituacaoEnum.ESOCIAL_ERRO_LOTE)] || 0 }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <hr>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="javascript:void(0)"
                           @click="this.routePush(eventoTipo, EsocialEventoSituacaoEnum.ESOCIAL_APROVADO)">
                            {{
                                EsocialEventoSituacaoEnumLabel.get(EsocialEventoSituacaoEnum.ESOCIAL_APROVADO)
                            }}
                        </a>
                    </td>
                    <td class="text-center">
                        {{ item[EsocialEventoSituacaoEnumKey.get(EsocialEventoSituacaoEnum.ESOCIAL_APROVADO)] || 0 }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <hr>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="javascript:void(0)"
                           @click="this.routePush(eventoTipo, EsocialEventoSituacaoEnum.SOLICITADO_RETIFICACAO)">
                            {{
                                EsocialEventoSituacaoEnumLabel.get(EsocialEventoSituacaoEnum.SOLICITADO_RETIFICACAO)
                            }}
                        </a>
                    </td>
                    <td class="text-center">
                        {{
                            item[EsocialEventoSituacaoEnumKey.get(EsocialEventoSituacaoEnum.SOLICITADO_RETIFICACAO)] || 0
                        }}
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="javascript:void(0)"
                           @click="this.routePush(eventoTipo, EsocialEventoSituacaoEnum.SOLICITADO_CANCELAMENTO)">
                            {{
                                EsocialEventoSituacaoEnumLabel.get(EsocialEventoSituacaoEnum.SOLICITADO_CANCELAMENTO)
                            }}
                        </a>
                    </td>
                    <td class="text-center">
                        {{
                            item[EsocialEventoSituacaoEnumKey.get(EsocialEventoSituacaoEnum.SOLICITADO_CANCELAMENTO)] || 0
                        }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <hr>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="javascript:void(0)"
                           @click="this.routePush(eventoTipo, EsocialEventoSituacaoEnum.RETIFICADO)">
                            {{ EsocialEventoSituacaoEnumLabel.get(EsocialEventoSituacaoEnum.RETIFICADO) }}
                        </a>
                    </td>
                    <td class="text-center">
                        {{ item[EsocialEventoSituacaoEnumKey.get(EsocialEventoSituacaoEnum.RETIFICADO)] || 0 }}
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="javascript:void(0)"
                           @click="this.routePush(eventoTipo, EsocialEventoSituacaoEnum.CANCELADO)">
                            {{ EsocialEventoSituacaoEnumLabel.get(EsocialEventoSituacaoEnum.CANCELADO) }}
                        </a>
                    </td>
                    <td class="text-center">
                        {{ item[EsocialEventoSituacaoEnumKey.get(EsocialEventoSituacaoEnum.CANCELADO)] || 0 }}
                    </td>
                </tr>
                </tbody>
            </table>
        </template>
    </Card>
</template>

<script>
import {
    EsocialEventoSituacaoEnum,
    EsocialEventoSituacaoEnumKey,
    EsocialEventoSituacaoEnumLabel
} from '../../eventos/enums/EsocialEventoSituacaoEnum';

export default {
    props: {
        eventoTipo: String,
        eventoTipoTitle: String,
        item: Object
    },
    data() {
        return {
            selectAll: false,
            EsocialEventoSituacaoEnum,
            EsocialEventoSituacaoEnumKey,
            EsocialEventoSituacaoEnumLabel
        };
    },
    methods: {
        routePush(eventoTipo, situacao) {
            this.$router.push({
                path: '/esocial/list',
                query: {evento_tipo: eventoTipo, situacao: situacao}
            });
        }
    }
};
</script>

<style scoped>

</style>
